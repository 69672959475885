export const BASE_URL = 'https://www.c3-captcha.de/api';

export function createApiUrl(path: string, token: string): URL {
    const url = new URL(BASE_URL + path);
    url.searchParams.set('apiToken', token);
    return url;
}

export interface Labels {
    options: Record<any, string>;
    question: string;
    buttons: {
        submit: string;
        submitted: string;
        retry: string;
        reset: string;
        help: string;
    }
    status: {
        invalid: string;
        pending: string;
    },
    info: {
        header?: string;
        text?: string;
    }[]
}

export interface GenerateResponse extends Response {
    json(): Promise<{
        token: string;
        question: string;
        options: number[];
        labels: Labels;
    }>
}

export function generate(language: string, apiToken: string): Promise<GenerateResponse> {
    const url = createApiUrl('/generate', apiToken);
    url.searchParams.append('lang',  language);
    return fetch(url, {
        cache: 'no-store',
    });
}

export interface ValidateResponse extends Response {
    json(): Promise<{
        valid: boolean;
        error?: string;
    }>
}

export function validate(token: string, answer: number[], apiToken: string): Promise<ValidateResponse> {
    const url = createApiUrl('/validate', apiToken);
    return fetch(url, {
        method: 'POST',
        body: JSON.stringify({token, answer}),
        cache: 'no-store',
        headers: {
            'Content-Type': 'application/json',
        }
    });
}

export interface IconsResponse<T extends string | number> extends Response {
    json(): Promise<Record<T, string>>
}

export async function fetchIcons<T extends string | number>(keys: T[], apiToken: string): Promise<IconsResponse<T>> {
    const url = createApiUrl('/images', apiToken);
    url.searchParams.set('keys', keys.join(','));
    return fetch(url, {
        cache: 'no-store',
    });
}