import {css, CSSResult, html, LitElement, PropertyValues, render, unsafeCSS} from 'lit';
import {unsafeHTML} from 'lit/directives/unsafe-html.js';
import {choose} from 'lit/directives/choose.js';
import {when} from 'lit/directives/when.js';
import {customElement, property, state} from 'lit/decorators.js';
import {repeat} from 'lit/directives/repeat.js';
import {fetchIcons, generate, Labels, validate} from './lib/api';
import help from './resources/help.svg';
import check from './resources/check.svg';
import reset from './resources/reset.svg';

export enum CaptchaState {
    Pending = 'pending',
    Valid = 'valid',
    Invalid = 'invalid',
    /**
     * the broken state is used when the api seems to be broken. We allow form submissions in that case.
     */
    Broken = 'broken'
}

function removeFocus(e: FocusEvent) {
    (e.target as HTMLInputElement).blur();
}

function size(scale: number): CSSResult {
    return unsafeCSS(`calc(${scale} * var(--captcha-base-size))`);
}

function wait(duration: number): Promise<void> {
    return new Promise(res => setTimeout(res, duration));
}

@customElement('c3-captcha')
export class Captcha extends LitElement {

    @property({attribute: 'api-token'})
    public apiToken!: string;

    @property()
    public language: string = 'en';

    @property({type: Boolean, reflect: true, attribute: 'info-open'})
    public infoOpen: boolean = false;

    @property({reflect: true, attribute: 'state'})
    private _state: CaptchaState = CaptchaState.Pending;

    @property({type: Boolean, reflect: true, attribute: 'validating'})
    private _validating: boolean = false;

    @property({type: Boolean, reflect: true, attribute: 'loading'})
    private _loading: boolean = false;

    @state()
    private _token: string = '';

    @state()
    private _options: number[] = [];

    @state()
    private _question: string = '';

    @state()
    private _checked: number[] = [];

    @state()
    private _labels!: Labels;

    private _outsideContainer = document.createElement('div');

    private _onWindowClickListener = (e: PointerEvent) => this._onWindowClick(e);

    private static _iconCache = new Map<any, string>();

    constructor() {
        super();
        this._outsideContainer.classList.add('c3captcha-input-container');
        this._outsideContainer.style.position = 'absolute';
        this._outsideContainer.style.height = '0';
        this._outsideContainer.style.opacity = '0';
        this._outsideContainer.style.overflow = 'hidden';
    }

    public connectedCallback() {
        super.connectedCallback();
        this.reset().catch(console.error);
        this.after(this._outsideContainer);
        window.addEventListener('pointerdown', this._onWindowClickListener);
    }

    public disconnectedCallback() {
        super.disconnectedCallback();
        window.removeEventListener('pointerdown', this._onWindowClickListener);
    }

    protected updated(changedProperties: PropertyValues) {
        super.updated(changedProperties);

        if (changedProperties.has('_token')
            || changedProperties.has('_state')
            || changedProperties.has('_checked')) {
            render(
                Captcha._renderOutsideInputs(this._token, this._checked, this._state, {
                    [CaptchaState.Pending]: this._labels?.status.pending,
                    [CaptchaState.Invalid]: this._labels?.status.invalid
                }),
                this._outsideContainer
            );
            const height = this._outsideContainer.scrollHeight;
            this._outsideContainer.style.marginTop = `${-height}px`;
        }
    }

    protected render() {
        return html`
            <div class="loader" part="loader">
                <div class="spinner" part="loader-spinner">
                    <div class="double-bounce1" part="loader-spinner-bounce1"></div>
                    <div class="double-bounce2" part="loader-spinner-bounce2"></div>
                </div>
            </div>

            <div class="question" part="question">
                <span class="question-text" part="question-text">${this._labels?.question} ${this._question}:</span>
                <button class="help-icon"
                        @click="${() => this.infoOpen = !this.infoOpen}"
                        part="help-icon"
                        title="${this._labels?.buttons.help}">
                    ${unsafeHTML(help)}
                </button>

                <div class="info" part="info">
                    ${repeat(this._labels?.info ?? [], item => html`
                        <div class="info-section" part="info-section">
                            ${when(item.header, () => html`
                                <p class="info-section-header" part="info-section-header">${unsafeHTML(item.header)}</p>
                            `)}
                            ${when(item.text, () => html`
                                <p class="info-section-text" part="info-section-text">${unsafeHTML(item.text)}</p>
                            `)}
                        </div>
                    `)}
                </div>
            </div>

            <div class="option-container">
                ${repeat(this._options, (option, idx) => html`
                    <input type="checkbox"
                           id="c3captcha-option-${idx}"
                           name="c3captcha[options][${idx}]"
                           value="${option}"
                           .checked="${this._checked.includes(idx)}"
                           ?disabled="${this._validating || this._state !== CaptchaState.Pending}"
                           @focusin="${this._addAriaLabel}"
                           @focusout="${this._removeAriaLabel}"
                           @change="${(e: InputEvent) => this._onChange(
                               idx,
                               (e.target as HTMLInputElement).checked
                           )}">
                    <label class="option" for="c3captcha-option-${idx}" aria-hidden="true" part="option">
                        <span class="option-icon" aria-hidden="true">
                            ${unsafeHTML(Captcha._iconCache.get(option))}
                        </span>
                    </label>
                `)}
            </div>

            <div class="btn-container" part="button-container">
                ${choose(this._state, [
                    [CaptchaState.Pending, () => html`
                        <button class="btn btn-primary"
                                @click="${this.validate}"
                                part="button-validate"
                                ?disabled="${this._validating}">
                            <span class="btn-icon" aria-hidden="true">
                                ${unsafeHTML(check)}
                            </span>
                            <span>${this._labels?.buttons.submit}</span>
                        </button>
                        <button class="btn btn-reset"
                                @click="${this.reset}"
                                part="button-reset"
                                title="${this._labels?.buttons.reset}"
                                ?disabled="${this._validating}">
                            <span class="btn-icon" aria-hidden="true">
                                ${unsafeHTML(reset)}
                            </span>
                        </button>
                    `],
                    [CaptchaState.Valid, () => html`
                        <span class="btn btn-success" part="button-success">
                            <span class="btn-icon" aria-hidden="true">
                                ${unsafeHTML(check)}
                            </span>
                            ${this._labels?.buttons.submitted}
                        </span>
                    `],
                    [CaptchaState.Invalid, () => html`
                        <button class="btn btn-primary" @click="${this.reset}" part="button-retry">
                            <span class="btn-icon" aria-hidden="true">
                                ${unsafeHTML(reset)}
                            </span>
                            <span>${this._labels?.buttons.retry}</span>
                        </button>
                    `]
                ])}
            </div>
        `;
    }

    /**
     * renders the required inputs outside the component. This is required, because inputs inside the component are not
     * recognized by a form-element
     * @param token the token of the captcha
     * @param answer the answer of the user
     * @param state the current state of the captcha
     * @param messages the required-messages for the browser
     * @private
     */
    private static _renderOutsideInputs(
        token: string,
        answer: number[],
        state: CaptchaState,
        messages: {[CaptchaState.Pending]: string, [CaptchaState.Invalid]: string}
    ) {
        const answerToken = state === CaptchaState.Broken ? 'broken' : token;
        return html`
            <input type="hidden" name="c3captcha[token]" value="${answerToken}" required>
            <input type="hidden" name="c3captcha[answer]" value="${JSON.stringify(answer)}" required>
            <input type="text"
                   @focus="${removeFocus}"
                   @invalid="${(e: InputEvent) => {
                       const input = e.target as HTMLInputElement;
                       // @ts-ignore
                       input.setCustomValidity(messages[state] ?? '');
                   }}"
                   tabindex="-1"
                   aria-hidden="true"
                   name="c3captcha[blocker]"
                   value="${this._getStateValue(state)}"
                   required>
        `;
    }

    /**
     * returns the blocker value for the passed state
     * @param state state to get value for
     * @private
     */
    private static _getStateValue(state: CaptchaState): string {
        switch (state) {
            case CaptchaState.Valid:
                return 'valid';
            case CaptchaState.Broken:
                return 'broken';
        }
        return '';
    }

    /**
     * validates if the current captcha input is valid and returns the result as a promise.
     */
    public async validate(): Promise<boolean> {
        if (this._validating || this._loading) {
            throw new Error('Validation is already in progress.');
        }

        this._validating = true;

        // show the loading state for at least half a second to give the user time to realise what's happening
        const [res] = await Promise.all([
            validate(this._token, this._checked, this.apiToken),
            wait(500)
        ]);

        if (!res.ok) {
            if (res.status >= 500) {
                this._state = CaptchaState.Broken;
                return false;
            }

            const ev: CaptchaValidateEvent = new CustomEvent('c3.captcha.validate', {
                detail: {
                    valid: false
                }
            });
            this.dispatchEvent(ev);

            this._validating = false;
            this._state = CaptchaState.Invalid;
            return false;
        }

        const data = await res.json();

        if (!data.valid) {
            console.error(data.error);
        }

        const ev: CaptchaValidateEvent = new CustomEvent('c3.captcha.validate', {
            detail: {
                valid: data.valid
            }
        });
        this.dispatchEvent(ev);

        this._state = data.valid ? CaptchaState.Valid : CaptchaState.Invalid;
        this._validating = false;
        return data.valid;
    }

    /**
     * resets the captcha widget to its initial state
     */
    public async reset(): Promise<void> {
        this._loading = true;
        const res = await generate(this.language, this.apiToken);

        if (!res.ok) {
            if (res.status >= 400) {
                this._state = CaptchaState.Broken;
            }

            throw new Error('Failed to generate captcha.');
        }

        const data = await res.json();

        const uniqueOptions = [...new Set(data.options)];
        const uncachedIcons = uniqueOptions.filter(key => !Captcha._iconCache.has(key));
        if (uncachedIcons.length) {
            const iconRes = await fetchIcons(uncachedIcons, this.apiToken);
            if (!iconRes.ok) {
                if (iconRes.status >= 400) {
                    this._state = CaptchaState.Broken;
                }

                throw new Error('Failed to fetch icons.');
            }

            const loadedIcons = await iconRes.json();
            uncachedIcons.forEach((key) => Captcha._iconCache.set(key, loadedIcons[key]));
        }

        this._token = data.token;
        this._options = data.options;
        this._question = data.question;
        this._labels = data.labels;
        this._state = CaptchaState.Pending;
        this._checked = [];
        this._loading = false;
    }

    /**
     * updates the current checked state
     * @param idx index of the checkbox to modify
     * @param checked checked state of the checkbox
     * @private
     */
    private _onChange(idx: number, checked: boolean): void {
        const set = new Set(this._checked);
        if (checked) {
            set.add(idx);
        } else {
            set.delete(idx);
        }
        this._state = CaptchaState.Pending;
        this._checked = Array.from(set);
    }

    /**
     * disables the info window, if the user clicks anywhere in the window that is not the captcha
     * @param e the window pointer event
     * @private
     */
    private _onWindowClick(e: PointerEvent): void {
        if (e.target !== this) {
            this.infoOpen = false;
        }
    }

    /**
     * adds the aria-label to the option when the user focuses it via tab-focus
     * @param e the focus event
     * @private
     */
    private _addAriaLabel(e: FocusEvent) {
        const target = e.target as HTMLInputElement;
        const key = parseInt(target.value);
        target.setAttribute('aria-label', this._labels.options[key]);
    }

    /**
     * removes the aria-label on tab focus
     * @param e the focus event
     * @see _addAriaLabel
     * @private
     */
    private _removeAriaLabel(e: FocusEvent) {
        const target = e.target as HTMLInputElement;
        target.removeAttribute('aria-label');
    }

    static styles = css`
        :host {
            --captcha-base-size: 1rem;

            --captcha-background: #efefef;

            --captcha-color-primary: #393C66;
            --captcha-color-primary-contrast: #fff;

            --captcha-color-error: #BF4055;
            --captcha-color-error-contrast: #fff;

            --captcha-color-success: #509568;
            --captcha-color-success-contrast: #fff;

            --captcha-color-font: #000;

            --captcha-option-default-background-stroke-color: #fff;
            --captcha-option-default-icon-stroke-color: #BFBFBF;
            --captcha-option-default-icon-fill-color: #F2F2F2;

            --captcha-option-checked-background-color: var(--captcha-color-primary);
            --captcha-option-checked-icon-stroke-color: var(--captcha-color-primary-contrast);
            --captcha-option-checked-icon-fill-color: transparent;

            --captcha-option-valid-background-color: var(--captcha-color-success);
            --captcha-option-valid-icon-stroke-color: var(--captcha-color-success-contrast);
            --captcha-option-valid-icon-fill-color: transparent;

            --captcha-option-invalid-background-color: var(--captcha-color-error);
            --captcha-option-invalid-icon-stroke-color: var(--captcha-color-error-contrast);
            --captcha-option-invalid-icon-fill-color: transparent;

            --captcha-option-size: ${size(3)};

            --captcha-spinner-size: ${size(3)};
            --captcha-spinner-color: #ababab;

            --captcha-padding-vertical: ${size(1.5)};
            --captcha-padding-horizontal: ${size(1)};

            --captcha-transition-speed-default: .3s;
            --captcha-transition-speed-focus: .1s;

            position: relative;
            display: inline-block;
            padding: var(--captcha-padding-vertical) var(--captcha-padding-horizontal);
            background: var(--captcha-background);
            border-radius: ${size(.5)};
            font: inherit;
            font-size: ${size(.875)};
            box-sizing: border-box;
            width: 340px;
            max-width: 100%;
        }

        * {
            box-sizing: border-box;
        }

        input[type="text"],
        input[type="checkbox"] {
            opacity: 0;
            width: 0;
            height: 0;
            margin: 0;
            padding: 0;
            position: absolute;
        }

        p {
            margin: 0;
        }

        .loader {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 3;
            background: var(--captcha-background);
            border-radius: ${size(.5)};
            opacity: 0;
            visibility: hidden;
            transition: var(--captcha-transition-speed-default) opacity, var(--captcha-transition-speed-default) visibility;
        }

        :host([loading]) .loader {
            opacity: 1;
            visibility: visible;
            transition-duration: 0s;
        }

        .option-container {
            display: flex;
            flex-wrap: wrap;
            gap: ${size(.25)};
            margin-bottom: ${size(1)};
            min-height: var(--captcha-option-size)
        }

        .option {
            --_option-background-color: var(--captcha-option-default-background-stroke-color);
            --_option-icon-stroke-color: var(--captcha-option-default-icon-stroke-color);
            --_option-icon-fill-color: var(--captcha-option-default-icon-fill-color);
            position: relative;
            cursor: pointer;
            border-radius: ${size(.25)};
            width: var(--captcha-option-size);
            height: var(--captcha-option-size);
            display: flex;
            justify-content: center;
            align-items: center;
            background: #fff;
            background: var(--_option-background-color);
            transition: var(--captcha-transition-speed-focus) outline, var(--captcha-transition-speed-focus) outline-offset, var(--captcha-transition-speed-focus) background;
        }

        :host([state="${unsafeCSS(CaptchaState.Valid)}"]) :checked + .option {
            --_option-background-color: var(--captcha-option-valid-background-color);
            --_option-icon-stroke-color: var(--captcha-option-valid-icon-stroke-color);
            --_option-icon-fill-color: var(--captcha-option-valid-icon-fill-color);
        }

        :host([state="${unsafeCSS(CaptchaState.Invalid)}"]) :checked + .option {
            --_option-background-color: var(--captcha-option-invalid-background-color);
            --_option-icon-stroke-color: var(--captcha-option-invalid-icon-stroke-color);
            --_option-icon-fill-color: var(--captcha-option-invalid-icon-fill-color);
        }

        :checked + .option {
            --_option-background-color: var(--captcha-option-checked-background-color);
            --_option-icon-stroke-color: var(--captcha-option-checked-icon-stroke-color);
            --_option-icon-fill-color: var(--captcha-option-checked-icon-fill-color);
        }

        .option-icon {
            width: ${size(1.5)};
            height: ${size(1.5)};
        }

        .option-icon svg {
            width: 100%;
            height: 100%;
        }

        .option-icon svg * {
            stroke: var(--_option-icon-stroke-color);
            fill: var(--_option-icon-fill-color);
            transition: var(--captcha-transition-speed-focus) stroke, var(--captcha-transition-speed-focus) fill;
        }

        .question {
            margin-bottom: ${size(1)};
            display: flex;
            justify-content: space-between;
            gap: ${size(1)};
            align-items: center;
            position: relative;
        }

        .question-text {
            font-weight: 500;
            color: var(--captcha-color-primary);
        }

        .help-icon {
            height: ${size(1)};
            width: ${size(1)};
            padding: ${size(.5)};
            margin: ${size(-.5)};
            box-sizing: content-box;
            border-radius: 1000px;
            outline-offset: calc(${size(-.5)} - 1px);
        }

        .help-icon svg {
            width: 100%;
            height: 100%;
        }

        .help-icon:focus-visible {
            outline-offset: calc(${size(-.5)} + 2px);;
        }

        .info {
            position: absolute;
            top: 100%;
            width: calc(100% + (var(--captcha-padding-horizontal) * 3));
            background: var(--captcha-color-primary);
            color: var(--captcha-color-primary-contrast);
            border-radius: ${size(1)};
            padding: ${size(1)} ${size(.5)};
            z-index: 2;
            left: calc(var(--captcha-padding-horizontal) * -1.5);
            margin-top: ${size(.75)};
            opacity: 0;
            visibility: hidden;
            transform: translateY(${size(.5)});
            transition: var(--captcha-transition-speed-default) opacity, var(--captcha-transition-speed-default) visibility, var(--captcha-transition-speed-default) transform;
        }

        :host([info-open]) .info {
            opacity: 1;
            visibility: visible;
            transform: translateY(0);
        }

        .info::before {
            content: "";
            width: 1em;
            height: 1em;
            transform: rotate(45deg);
            background: var(--captcha-color-primary);
            display: block;
            right: ${size(1.55)};
            top: -.4em;
            position: absolute;
        }

        .info-section {
            position: relative;
        }

        .info-section + .info-section {
            padding-top: ${size(1.5)};
        }

        .info-section + .info-section::before {
            content: "";
            position: absolute;
            left: 0;
            top: ${size(.75)};
            width: 100%;
            height: 1px;
            background: currentColor;
            opacity: .1;
        }

        .info-section-header {
            margin-bottom: ${size(.5)};
        }

        .info-section-text {
            font-size: ${10 / 14}em;
            opacity: .6;
            line-height: 1.4;
        }

        .info-section-text a {
            color: currentColor;
        }

        .btn-container {
            display: flex;
            gap: ${size(.5)};
        }

        button {
            font: inherit;
            border: none;
            background: none;
            cursor: pointer;
            padding: 0;
        }

        .btn {
            padding: 0 ${size(.875)};
            height: ${size(2)};
            border-radius: 1000px;
            display: inline-flex;
            align-items: center;
            background: #fff;
            color: currentColor;
            gap: ${size(.25)};
            font-weight: 500;
        }

        .btn span:not(.btn-icon) {
            padding-right: ${size(.5)};
        }

        .btn-primary {
            color: var(--captcha-color-primary-contrast);
            background-color: var(--captcha-color-primary);
        }

        .btn-success {
            color: var(--captcha-color-success);
        }

        .btn-reset {
            color: var(--captcha-color-primary);
        }

        .btn-icon {
            width: ${size(1.25)};
            height: ${size(1.25)};
            display: block;
        }

        .btn-icon svg {
            width: 100%;
            height: 100%;
        }

        .btn-icon path {
            fill: currentColor;
        }

        button,
        .option {
            outline: 2px solid transparent;
            outline-offset: -1px;
        }

        button {
            transition: var(--captcha-transition-speed-focus) outline, var(--captcha-transition-speed-focus) outline-offset;
        }
        
        button[disabled] {
            opacity: .6;
        }

        button:focus-visible,
        :focus-visible + .option {
            outline-color: var(--captcha-color-font);
            outline-offset: 2px;
        }

        .spinner {
            width: var(--captcha-spinner-size);
            height: var(--captcha-spinner-size);
            position: relative;
        }

        .double-bounce1, .double-bounce2 {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            background-color: var(--captcha-spinner-color);
            opacity: 0.6;
            position: absolute;
            top: 0;
            left: 0;

            -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
            animation: sk-bounce 2.0s infinite ease-in-out;
        }

        .double-bounce2 {
            -webkit-animation-delay: -1.0s;
            animation-delay: -1.0s;
        }

        @keyframes sk-bounce {
            0%, 100% {
                transform: scale(0.0);
            }
            50% {
                transform: scale(1.0);
            }
        }
    `;
}

export type CaptchaValidateEvent = CustomEvent<{valid: boolean}>

export interface CaptchaEventMap {
    'c3.captcha.validate': CaptchaValidateEvent;
}

declare global {
    interface HTMLElementTagNameMap {
        'c3-captcha': Captcha;
    }

    interface Captcha {
        addEventListener<K extends keyof CaptchaEventMap>(
            type: K,
            listener: (this: Captcha, ev: CaptchaEventMap[K]) => void
        ): void;
    }
}